<template>
      <!-- Main content -->
      <section class="content">
        
      </section>
      <!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    import {
        md5
    }
    from '@/config/md5'
    export default {
        name: 'home',
        components: {},
        data() {
            return {

            }
        },
        watch: {},
        mounted() {},
        methods: {

        }
    }
</script>
<style scoped>

</style>